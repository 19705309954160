import { createStore } from "vuex";
import loadingModule from "./modules/loading.module";
import governorsModule from "./modules/governors.module";
import corporationsModule from "./modules/corporations.module";
import nieacMembersModule from "./modules/nieac-members.module";
import pressReleasesModule from "./modules/press-releases.module";
import managementTeamModule from "./modules/management-team.module";
import investorSupportServicesModule from "./modules/investor-support-services.module";

export default createStore({
  modules: {
    loadingModule,
    governorsModule,
    nieacMembersModule,
    corporationsModule,
    pressReleasesModule,
    managementTeamModule,
    investorSupportServicesModule,
  },
});

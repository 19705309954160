<template>
  <div class="bg-grey">
    <section id="footer" class="container">
      <!-- Logo and Social Links -->
      <div class="flex items-center justify-between mt-6 md:col-span-2">
        <div
          class="
            w-9/12
            mx-auto
            flex flex-col-reverse
            md:flex-col md:mx-0 md:space-y-4
            lg:w-60
            xl:w-80
          "
        >
          <router-link to="/" class="w-full">
            <img src="../../assets/images/nasida-logo.svg" alt="" />
          </router-link>
          <div
            class="
              flex
              items-center
              justify-between
              mb-4
              md:flex-col md:items-start
              lg:flex-row lg:items-center
            "
          >
            <a class="text-xs" href="mailto:info@nasida.na.gov.ng"
              >info@nasida.na.gov.ng</a
            >
            <div class="flex items-center space-x-3 md:mt-3 lg:mt-0">
              <a href="https://facebook.com" target="_blank">
                <Icon icon="uit:facebook-f" height="18" width="18" />
              </a>
              <a href="https://instagram.com" target="_blank">
                <Icon icon="uil:instagram" height="18" width="18" />
              </a>
              <a href="https://twitter.com" target="_blank">
                <Icon icon="uit:twitter-alt" height="18" width="18" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- NASIDA navigation links -->
      <Accordion title="NASIDA" class="md:flex-1">
        <router-link to="/investment-opportunities">
          Invest In Nasarawa
        </router-link>
        <router-link to="/who-we-are"> About Us </router-link>
        <router-link to="/contact-us"> Contact Us </router-link>
      </Accordion>

      <!-- Quick links -->
      <Accordion title="Quick Links" class="md:flex-1">
        <a href="https://www.nipc.gov.ng/" target="_blank"> NIPC  </a>
        <router-link to="/press-releases"> Press Releases </router-link>
        <router-link to="/investment-opportunities">
          Investment Opportunities
        </router-link>
        <router-link to="/investment-opportunities#incentives">
          Investment Incentives
        </router-link>
        <router-link to="/why-nasarawa"> Nasarawa </router-link>
        <router-link to="/investment-opportunities#key-sectors">
          Key Sectors
        </router-link>
      </Accordion>

      <!-- Download Publications -->
      <Accordion title="Download Publications" class="md:col-span-1">
        <p
          class="cursor-pointer"
          v-for="publication in publications"
          :key="publication.id"
          @click="downloadService(publication)"
        >
          {{ publication.name }}
        </p>
      </Accordion>
    </section>
    <section id="info-bar" class="container">
      <div class="flex items-center space-x-2 mb-2 md:mb-0">
        <Icon icon="fluent:mail-28-regular" height="20" width="20" />
        <a class="text-xs" href="mailto:info@nasida.na.gov.ng"
          >info@nasida.na.gov.ng</a
        >
      </div>
      <!-- <div class="flex items-center space-x-2 mb-2 md:mb-0 md:mx-auto">
        <Icon icon="carbon:phone-voice" height="20" width="20" />
        <a class="text-xs" href="tel:+2341234567890">+234-123-456-7890</a>
      </div> -->
      <div class="flex items-center space-x-2 md:ml-auto">
        <Icon icon="ant-design:clock-circle-outlined" height="20" width="20" />
        <p class="text-xs">Mon-Fri: 9:00 - 17:00, Sat, Sun Closed</p>
      </div>
    </section>
  </div>
</template>

<script src="./footer.component.js"></script>
<style src="./footer.component.css" scoped lang="css"></style>

import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Accordion from "@/components/accordion/index.vue";

export default defineComponent({
  name: "Footer",
  components: {
    Accordion,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch("getAllSupportServices");
    });

    const publications = computed(
      () => store.state.investorSupportServicesModule.investorSupportServices
    );

    const downloadService = (service) => {
      store.dispatch("startLoading");
      fetch(service.link)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = service.name;
          link.click();
        })
        .then(() => {
          store.dispatch("stopLoading");
        })
        .catch(console.error);
    };

    return { publications, downloadService };
  },
});

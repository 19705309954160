import { httpService } from "../../services/http.service";
import { Bio } from "../models/bio.model";

const state = {
  nieacMembers: null,
};

const actions = {
  async getNIEACMembers({ commit }) {
    const response = await httpService.get("members");
    const nieacMembers = [];
    response.data.data.forEach((member) => {
      nieacMembers.push(new Bio(member));
    });
    commit("setNIEACMembers", nieacMembers);
  },
};

const mutations = {
  setNIEACMembers(state, nieacMembers) {
    state.nieacMembers = nieacMembers;
  },
};

export default { state, mutations, actions };

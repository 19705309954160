import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Icon } from '@iconify/vue'
import VueSplide from '@splidejs/vue-splide';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import "animate.css";

const app = createApp(App);

// console.log(Vue.version)

app.use(store).use(router)
app.use(VueSplide)

app.component('Icon', Icon)

app.mount('#app')
import { httpService } from "../../services/http.service";
import { Bio } from "../models/bio.model";

const state = {
  governor: null,
};

const actions = {
  async getGovernor({ commit }) {
    const response = await httpService.get("governors");
    const governor = new Bio(response.data.data[0])
    commit("setGovernor", governor);
  },
};

const mutations = {
  setGovernor(state, governor) {
    state.governor = governor;
  },
};

export default { state, mutations, actions };

import { mainRoutes, constructionRoutes } from "./routes";
import { authGuard, metaWrapper } from "./guards";
import { createRouter, createWebHistory } from "vue-router";
import { underConstruction } from "../layouts/default/default";

const routes = underConstruction ? constructionRoutes : mainRoutes

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else if (to.name == 'Sector' && from.name == 'Sector') {
      return {
        top: 900,
        behavior: 'smooth',
      };
    } else {
      return {
        top: 0,
        behavior: 'smooth',
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  authGuard(to, next);
  metaWrapper(to, from)
});

export default router;

import { httpService } from "../../services/http.service";
import { Corporation } from "../models/corporation.model";

const state = {
  corporations: null,
};

const actions = {
  async getAllCorporations({ commit }) {
    const response = await httpService.get("corporations");
    const corporations = [];
    response.data.data.forEach((corporation) => {
      corporations.push(new Corporation(corporation));
    });
    commit("setCorporations", corporations);
  },
};

const mutations = {
  setCorporations(state, corporations) {
    state.corporations = corporations;
  },
};

export default { state, mutations, actions };

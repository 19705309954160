import { defineComponent } from "vue";

import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";

export const underConstruction = false;

export default defineComponent({
  name: "DefaultLayout",
  components: {
    Header,
    Footer,
  },
  setup() {
    return { underConstruction };
  },
});

import axios from "axios";
import store from "../store";

const config = {
    baseURL: 'https://api.nasida.na.gov.ng/api/',
    // baseURL: 'http://127.0.0.1:8000/api/',
    headers: {
        'Content-Type': 'application/json'
    }
}

let activeRequests = 0;

const httpService = axios.create(config)

httpService.interceptors.request.use(
    (config) => {
        if (activeRequests === 0) {
            store.dispatch('startLoading')
        }
        
        activeRequests++
        
        return config;
    }
)

httpService.interceptors.response.use(
    (response) => {
        activeRequests--
        if (activeRequests === 0) {
            store.dispatch('stopLoading')
        }
        
        return response
    },
    (error) => {
        activeRequests--
        
        if (activeRequests === 0) {
            store.dispatch('stopLoading')
        }

        if (error.response.status === 401) {
            // Throw Unauthorized Error Message
        } else if (error.response.status === 404) {
            console.log(error.response.data.message)
        } else if (error.response.status === 500) {
            console.log('Something went wrong')
        }

        return Promise.reject(error);
    }
)

export { httpService }
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Accordion",
  components: {},
  props: ["title", "spaced", "closed"],
  setup(props) {
    const active = ref(!props.closed);
    return { active };
  },
});

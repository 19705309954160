export class Bio {
  constructor(bio) {
    this.id = bio.id;
    this.name = bio.name;
    this.photo = bio.photo;
    this.profilePhoto = bio.photo_two;
    this.profile = bio.profile?.split("\n") || bio.bio?.split("\n");
  }
}

export class TeamMember extends Bio {
  constructor(teamMember) {
    super(teamMember);
    this.position = teamMember.position;
  }
}

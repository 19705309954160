import { defineComponent } from "vue";

export default defineComponent({
  name: "Anchor Link",
  props: {
    text: {
      type: String,
      default: "learn more",
    },
    color: {
      type: String,
      default: "green",
    },
    url: {
      type: String,
      default: "#",
    },
    isDropDownLink: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    isSub: {
      type: Boolean,
      default: false
    },
  },
});

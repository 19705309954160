import { defineComponent, shallowRef } from "vue";
import BaseLayout from "@/layouts/base/index.vue";

import "@/assets/styles/styles.css";

export default defineComponent({
  name: "App",
  data: () => ({
    layout: shallowRef(BaseLayout)
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          const component = await import(`@/layouts/${route.meta.layout}/index.vue`)
          this.layout = shallowRef(component?.default) || shallowRef(BaseLayout);
        } catch (e) {
          this.layout = shallowRef(BaseLayout);
        }
      }
    }
  }
});

<template>
  <div class="flex flex-col space-y-3">
    <div class="header">
      <a
        href="#"
        class="flex items-center"
        :class="{ 'justify-between': !spaced, 'justify-end': spaced }"
        @click.prevent="active = !active"
      >
        <p class="font-bold" :class="{ 'mr-2': spaced }">{{ title }}</p>

        <div class="md:hidden">
          <Icon
            icon="akar-icons:chevron-up"
            class="cursor-pointer text-dark-blue"
            height="20"
            width="20"
            v-show="active"
          />
          <Icon
            icon="akar-icons:chevron-down"
            class="cursor-pointer text-dark-blue"
            height="20"
            width="20"
            v-show="!active"
          />
        </div>
      </a>
    </div>
    <div class="content flex flex-col space-y-2 md:text-sm" v-show="active">
      <slot />
    </div>
  </div>
</template>
<script src="./accordion.component.js" lang="js"></script>
<style src="./accordion.component.css" scoped lang="css"></style>


import { httpService } from "../../services/http.service";
import { PressRelease } from "../models/press-release.model";

const state = {
  pressReleases: [],
};

const actions = {
  async getAllPressReleases({ commit }, page) {
    const response = await httpService.get(`press-releases?page=${page}`);
    const pressReleases = [];
    response.data.data.data.forEach((pressRelease) => {
      const _currentPressRelease = new PressRelease(pressRelease);
      let duplicatePressRelease = false;
      state.pressReleases.map((pressRelease) => {
        if (pressRelease.id == _currentPressRelease.id) {
          duplicatePressRelease = true;
        }
      });
      if (!duplicatePressRelease) {
        pressReleases.push(_currentPressRelease);
      }
    });
    if (state.pressReleases.length <= response.data.data.per_page * page) {
      commit("setPressReleases", pressReleases);
    }
    return response.data.data.next_page_url
  },
  async getPressRelease(context, id) {
    const response = await httpService.get(`press-releases/${id}`);
    const pressRelease = new PressRelease(response.data.data);
    return pressRelease;
  },
};

const mutations = {
  setPressReleases(state, pressReleases) {
    state.pressReleases.push(...pressReleases);
  },
};

export default { state, mutations, actions };

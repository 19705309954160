import { httpService } from "../../services/http.service";
import { SupportService } from "../models/support-service.model";

const state = {
  investorSupportServices: [
    // {
    //   name: "Project Facilitation",
    //   link:
    //     "https://media.africaportal.org/documents/CPED_Monograph_Series_No._8_-_The_Nigerian_Economy_Reforms.pdf",
    // },
    // {
    //   name: "Land Allocation Assistance",
    //   link:
    //     "https://media.africaportal.org/documents/CPED_Monograph_Series_No._8_-_The_Nigerian_Economy_Reforms.pdf",
    // },
    // {
    //   name: "Handholding Services",
    //   link:
    //     "https://media.africaportal.org/documents/CPED_Monograph_Series_No._8_-_The_Nigerian_Economy_Reforms.pdf",
    // },
  ],
};

const actions = {
  async getAllSupportServices({ commit }) {
    const response = await httpService.get("investor-support-services");
    const investorSupportServices = [];
    response.data.data.forEach((supportService) => {
      investorSupportServices.push(new SupportService(supportService));
    });
    commit("setSupportServices", investorSupportServices);
  },
};

const mutations = {
  setSupportServices(state, investorSupportServices) {
    state.investorSupportServices = investorSupportServices;
  },
};

export default { state, mutations, actions };

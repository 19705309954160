import { httpService } from "../../services/http.service";
import { TeamMember } from "../models/bio.model";

const state = {
  managementTeam: null,
  mdCEO: null,
};

const actions = {
  async getManagementTeam({ commit }) {
    const response = await httpService.get("teams");
    const managementTeam = [];
    response.data.data.forEach((member) => {
      if (member.position !== "MD/CEO") {
        managementTeam.push(new TeamMember(member));
      }
    });
    commit("setManagementTeam", managementTeam);
  },
  async getMDCEO({ commit }) {
    const response = await httpService.get("teams");
    const mdCEO = new TeamMember(
      response.data.data.find((member) => member.position == "MD/CEO")
    );
    commit("setMDCEO", mdCEO);
  },
};

const mutations = {
  setManagementTeam(state, managementTeam) {
    state.managementTeam = managementTeam;
  },
  setMDCEO(state, mdCEO) {
    state.mdCEO = mdCEO;
  },
};

export default { state, mutations, actions };
